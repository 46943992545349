import React from 'react'
import Layout from '../components/Layout'

const PrivacyPage = () => (
  <Layout>
    <section className='hero is-primary'>
      <div className='hero-body subpage'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10'>
              <h2 className='is-size-2 anthracite-70-text capitalize page-heading'>
                Datenschutz
              </h2>
            </div>
            <div className='column is-2 anthracite-70-text text-center breadcrumb'>
              <span>{'HOME > Datenschutz'}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='yellow-border' />
    <section className='section section--gradient without-vertical-padding'>
      <div className='container anthracite-text'>
        <div className='section without-vertical-padding'>
          <div className='columns'>
            <div className='column is-6'>
              <div className='content'>
                <p className='is-size-5'>
                  Hier muss etwas zum Datenschutz stehen…
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PrivacyPage
